'use client';

import Alert from '@mui/material/Alert';
import * as Sentry from '@sentry/nextjs';
import React, { ReactNode, useEffect } from 'react';

import { Group } from '@/components/constants';
import useAuth from '@/components/hooks/useAuth';
import Progress from '@/components/Progress';
import Signin from '@/components/Signin';
import { AppPermissions, createEmptyPermissions } from '@/models/AppPermission';

type Props = {
  children?: ReactNode;
};

export function AuthedRoute({ children }: Props) {
  const { me, isLoading } = useAuth();

  useEffect(() => {
    if (me) {
      Sentry.setUser({ username: me.username });
    } else {
      Sentry.setUser(null);
    }
  }, [me]);

  if (isLoading) {
    return null;
  }

  if (!me) {
    return <Signin />;
  }

  return <>{children}</>;
}

export default function useAuthed() {
  const { me, ...auth } = useAuth();

  if (!me) {
    throw new Error(`Not authenticated`);
  }

  return {
    ...auth,
    me,
    permissions: auth.permissions || createEmptyPermissions(),
  };
}

export function WithPermissionRequired({
  children,
  when,
  showErrorMessage = false,
}: {
  children: ReactNode;
  when: {
    ({
      permissions,
      groupNames,
    }: {
      permissions: AppPermissions;
      groupNames: Group[];
    }): boolean;
  };
  showErrorMessage?: boolean;
}) {
  const { permissions, groupNames } = useAuth();

  if (permissions === null) {
    return <Progress />;
  }

  if (!when({ permissions, groupNames })) {
    if (showErrorMessage) {
      return (
        <Alert severity="error">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          You don't have permission to view this page
        </Alert>
      );
    }
    return null;
  }

  return <>{children}</>;
}
