'use client';

import { useCallback, useMemo } from 'react';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { Group } from '@/components/constants';
import useErrorSnackbar from '@/components/hooks/useErrorSnackbar';
import useMe from '@/components/hooks/useMe';
import {
  MyGroupsAndVenuesDocument,
  MyGroupsAndVenuesQuery,
  MyGroupsAndVenuesQueryVariables,
  useLoginMutation,
  useLogoutMutation,
} from '@/generated/graphql';
import client from '@/graphql/apollo';
import { getPermissionsByGroups } from '@/models/AppPermission';

export default function useAuth() {
  const { data: me, mutate, error, isLoading } = useMe();

  useErrorSnackbar(error);

  const refresh = useCallback(async () => {
    await mutate();
  }, [mutate]);
  const [loginMutation] = useLoginMutation();
  const [logoutMutation] = useLogoutMutation();

  const login = useCallback(
    async (username: string, password: string) => {
      const response = await loginMutation({
        variables: {
          username,
          password,
        },
      });
      if (!response.data?.login) {
        throw new Error(`Invalid username or password`);
      }
      // Handle backwards compatibility with old auth token
      await refresh();
    },
    [loginMutation, refresh],
  );
  const logout = useCallback(async () => {
    await logoutMutation();
    // Handle backwards compatibility with old auth token
    await refresh();
  }, [logoutMutation, refresh]);
  const { data: groups } = useSWRImmutable([`/MyGroups`, me?.id], async () => {
    if (!me) {
      return null;
    }
    return client
      .query<MyGroupsAndVenuesQuery, MyGroupsAndVenuesQueryVariables>({
        query: MyGroupsAndVenuesDocument,
      })
      .then((it) => it.data.me?.groups);
  });

  const groupNames = useMemo(
    () => groups?.map((it) => it.name as Group) ?? [],
    [groups],
  );

  const { data: permissions = null } = useSWR(
    [`permission`, groups],
    async () => {
      if (!groupNames) {
        return null;
      }
      return getPermissionsByGroups(groupNames);
    },
  );

  return {
    me,
    login,
    logout,
    isLoading,
    groups,
    groupNames,
    permissions,
  };
}
